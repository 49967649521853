<template>
  <article id="error">
    <div class="error__container">
      <span class="error__ornament en">ERROR</span>
      <h1 class="error__code en">{{ errorMessage.statusCode }}</h1>
      <h2 class="error__title my-4">{{ errorMessage.title }}</h2>
      <p
        v-if="errorMessage.description"
        class="error__description"
        v-text="errorMessage.description"
      />
      <a href="/"><span>ホームに戻る</span></a>
    </div>
  </article>
</template>

<script lang="ts">
import { PropType } from 'vue'
import { createErrorMessage, ErrorMessage } from '~/shared/error'

interface Error {
  statusCode: number
  message: string
}

export default defineComponent({
  props: {
    error: {
      type: Object as PropType<Error>,
      required: true,
    },
  },

  computed: {
    errorMessage(): ErrorMessage {
      return createErrorMessage(this.error.statusCode)
    },
  },
})
</script>

<style lang="sass" scoped>
#error
  min-height: 85vh
  display: grid
  place-items: center

.error__container
  display: flex
  flex-direction: column
  align-items: center
  margin: auto
  padding: 20px 40px

.error__ornament
  color: rgba(0, 0, 0, 0.1)
  text-shadow: 1px 1px 0.5px #e2e2e2, 0 0 #c1c1c1
  opacity: 0.72
  font-size: 24px
  font-style: normal
  font-weight: bold

.error__code
  color: rgba(0, 0, 0, 0.1)
  text-shadow: 1px 1px 0.5px #e2e2e2, 0 0 #c1c1c1
  opacity: 0.72
  font-size: 64px
  font-style: normal
  font-weight: bold
  line-height: 1

.error__title
  color: $sub-color
  font-weight: 600
  font-size: 16px
  line-height: 24px

.error__description
  font-size: 14px
  text-align: center
  white-space: pre-line

.error__definition
  font-size: 14px
</style>
