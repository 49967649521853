export interface ErrorMessage {
  statusCode: number
  title: string
  description?: string
}
export const createErrorMessage = (statusCode: number) => {
  switch (statusCode) {
    case 401:
      return {
        statusCode,
        title: 'ログインしてから再度お試しください',
      }
    case 403:
      return {
        statusCode,
        title: 'アクセスが許可されていません',
      }
    case 404:
      return {
        statusCode,
        title: 'お探しのページは見つかりませんでした',
        description:
          '大変申し訳ございません。\n一時的にアクセスできない状態にあるか、\n移動もしくは削除された可能性があります。',
      }
    case 500:
      return {
        statusCode,
        title: 'サーバーで問題が発生しています',
      }
    case 503:
      return {
        statusCode,
        title: '一時的にサーバーが停止しています',
      }
    default:
      return {
        statusCode,
        title: 'ページが表示できませんでした',
      }
  }
}
